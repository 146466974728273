:root {
    --font-family-roboto: 'Roboto';
    --font-weight-500: 500;
    --font-size-18px: 16px;
    --line-height-24px: 24px;
    --letter-spacing-05px: 0.5px;
    /* --padding-base: 16px;  */
    /* --gap-20xl: 20px; */
    --gap-base: 60px; 
    --padding-5xs: 18px; 
    --font-size-lg: 18px; 
    --text: #333;
    --gap-5xs: 65px; 
    --padding-base: 2px; 
    --gap-6xs: 6px;
    --color-gainsboro-100:#E6E6E6;
    --text-color: #333; 
    --font-body1: 'Roboto', sans-serif;
    --font-weight-body1: 400;
    --font-size-body1: 13px;
    --line-height-body1: 24px;
    --letter-spacing-body1: 0.5px;
    --padding-sm: 12px; 
    --padding-mini: 8px; 
    --header-padding: 10px; 
    --row-padding: 160px;
    --row-paddingphone: 10px;
}
.picIcon {
    height: 32px;
    width: 32px;
    position: relative;
  }
  /* .div1 {
    height: 52px;
    overflow: hidden;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-sm) var(--padding-3xs);
    box-sizing: border-box;
  }
   */
  .downArrow1Icon {
    /* height: 10px;
    width: 10px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0; */
    height: 12px;
    width: 12px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .header
   {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-base) 70px 2px var(--padding-base);
    gap: var(--gap-base);
    flex-shrink: 0;

  }
  /* Main container for patient overview */
.patientOverview {
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

/* Content inside patientOverview */
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

/* Stretch the filterWrapper */
.filterWrapper {
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  text-align: left;
  font-size: var(--body1-1-size);
  color: var(--body1);
  font-family: var(--body-1);
  box-sizing: border-box;
}

/* Stretch the row across the page */
.row, .row1 {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: flex-start;
  width: 100%; 
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
}


.header1{
  flex-grow: 1; 
  display: inline-block;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  box-sizing: border-box;
}

/* Add some margin to separate the name and icon */
.nameicon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
  .headin {
    position: relative;
    /* letter-spacing: 0.5px;
    line-height: 24px;
    font-weight: 500; */
    display: inline-block;
    font-family: var(--font-family-roboto);
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-18px);
    line-height: var(--line-height-24px);
    letter-spacing: var(--letter-spacing-05px);
    color: #6D6B80;
  }
  .downArrow1Icon1 {
    height: 10px;
    width: 10px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;

  }
  .downArrow1Icon2 {
    height: 10px;
    width: 10px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;

  }
  .nameicon
  {
    display: flex;
    align-items: center;
    gap: var(--gap-5xs);
    padding: var(--padding-base) 30px var(--padding-base) var(--padding-base);
    /* padding: var(--padding-base); */
  }
  
  .headin4 {
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 71px;
  }
 
  .headin5 {
    height: 24px;
    flex: 1;
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
  }
  .downArrow1Icon5 {
    height: 10px;
    width: 10px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  
  .picIcon1 {
    height: 32px;
    width: 32px;
    position: relative;
  }
 
  .frameChild,
  .stateika21Icon {
    height: 33px;
    width: 33px;
    position: relative;
  }
  .frameChild {
    border-radius: 50%;
    background-color: var(--color-gainsboro-100);
    display: none;
  }
  .stateika21Icon {
    border-radius: var(--br-81xl);
    object-fit: cover;
  }
  .patientnametext {
    position: relative;
    /* letter-spacing: 0.5px;
    line-height: 24px; */
    display: block;
    min-width: 79px;
    font-family: var(--font-body1);
    font-weight: var(--font-weight-body1);
    font-size: var(--font-size-body1);
    line-height: var(--line-height-body1);
    letter-spacing: var(--letter-spacing-body1);
    color: var(--text-color);
  }
  .patientphonetext {
    /* position: relative; */
    display: block;
    font-family: var(--font-body1);
    font-weight: var(--font-weight-body1);
    font-size: var(--font-size-body1);
    line-height: var(--line-height-body1);
    letter-spacing: var(--letter-spacing-body1);
    color: var(--text-color);

  }
  .divname,
  .ellipseParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .ellipseParent {
    gap: var(--gap-6xs);
  }
  .divname {
    width: 195px;
    overflow: hidden;
    flex-shrink: 0;
    padding: var(--padding-3xs-5) var(--padding-base);
    box-sizing: border-box;
  }
  .text {
    flex: 1;
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
  }
  
  .text1 {
    flex: 1;
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
  }

  .divcontent {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0 var(--row-padding) 0 0;
    gap: var(--gap-base);;
    /* background-color: var(--row-bg-color);
    font-size: var(--row-font-size); */
    text-align: left;
  }
  .divcontentphone {
    overflow: hidden;
    /* display: flex;
    flex-direction: row; */
    align-items: left;
    justify-content: flex-start;
    gap: var(--gap-base);
  
  }
  .frameItem {
    height: 12px;
    width: 12px;
    position: relative;
    border-radius: 50%;
    background-color: var(--bullet-pink);
    display: none;
  }
  
  .divaddress {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
   
  }
  
  .div {
    align-self: stretch;
    flex-direction: column;
    padding: 10px 29px 0 0;
    /* gap: var(--gap-5xs); */
    margin-top: -2px;
    margin-left: 25px;
   
  }
  
  
  @media screen and (max-width: 925px) {
    .row
    {
      flex-wrap: wrap;
    }
    .content {
      max-width: 100%;
    }
    .patientOverview {
      gap: var(--gap-lgi);
      padding-left: var(--padding-xl);
      padding-right: var(--padding-xl);
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 450px) {
    .header
     {
      flex: 1;
    }
  }
  