:root {
    --padding-4xs: 24px; 
    --font-size-11xl: 32px; 
    --padding-12xs: 18px; 
    --padding-lg: 24px; 
    --font-montserrat: 'Montserrat', sans-serif;
    --gap-xl:5px;
    /* --m3-label-large-size:30px; */
    /* --gap-xl:5px; */
    --gap-4xs:10px;
    --color-card-background:#FFFFFF;
    /* --padding-5xs:5px; */
    --gap-9xs:9px;
  }
.homeIcon {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .breadcrumbItemsCore {
    display: none;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .chevronRightIcon,
  .homeIcon1 {
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .chevronRightIcon {
    height: 16px;
    width: 16px;
    display: none;
  }
  .homeIcon1 {
    height: 24px;
    width: 24px;
  }
  .breadcrumbItemsCore2 {
    display: none;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .text {
    /* position: relative;
    line-height: 150%;
    display: inline-block;
    min-width: 54px; */
    font-family: Mulish, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: #667085;
  }
  .text1 {
    /* position: relative;
    line-height: 150%;
    display: inline-block;
    min-width: 54px; */
    font-family: Mulish, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: #101010;
  }
  .textpatient {
    /* position: relative;
    line-height: 150%;
    display: inline-block;
    min-width: 54px; */
    font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #06080b;
  }
  .breadcrumbItemsCore1,
  .breadcrumbItemsCore3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .breadcrumbItemsCore1 {
    gap: var(--gap-5xs);
  }
  .chevronRightIcon1 {
    height: 16px;
    width: 16px;
    position: relative;
    /* overflow: hidden;
    flex-shrink: 0; */
  }
  .dotIcon
  {
    position: relative;
  }
  .dotIcon {
    height: 8px;
    width: 8px;
    display: none;
  }
  .icon {
    height: 12px;
    width: 12px;
    position: relative;
  }
  .patientname {
    position: relative;
    line-height: 150%;
    display: inline-block;
    min-width: 99px;
  }
  .iconLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .leftContent {
    flex-direction: row;
    gap: var(--gap-9xs);
    color: var(--tailwind-gray900);
    font-family: var(--inter-body-medium-14-regular);
  }
  /* .breadcrumbMain,
  .breadcrumbWrapper,
  .leftContent,
  .mainContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  } */
  .breadcrumbMain {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: var(--gap-5xs);
  }
  .breadcrumbWrapper
   {
    flex-direction: column;
    padding: 0 var(--padding-5xs);
  }
  .mainContent {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 var(--padding-12xs) var(--padding-lg);
    
  }
  .patientProfile {
    margin: 0;
    position: relative;
    /* font-size: inherit;
    line-height: 150%;
    font-weight: 600;
    font-family: inherit; */
    font-family: var(--font-montserrat);
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
  }
  .image5Icon {
    width: 90px;
    height: 90px;
    position: relative;
    border-radius: var(--br-81xl);
    object-fit: contain;
  }
  .eleanoPena
   {
    position: relative;
    /* line-height: 150%; */
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

  }
  .genderMaleM {
   font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
  .nameDob {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3px;
  }
  .buttontext {
    position: relative;
    font-size: var(--font-size-lg);
    line-height: 150%;
    font-family: 'Inter', sans-serif;
    color:  #FFFFFF;
    text-align: left;
    display: inline-block;
    min-width: 32px;
  }
  .buttonLabels {
    cursor: pointer;
    border: 0;
    padding: 7px 14px;
    background-color: #60A5FA;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 480px;
  }
  
  .buttonLabels:hover {
    background-color: #6aafe6;
  }
  .bookAppointment {
    position: relative;
    font-size: var(--font-size-lg);
    line-height: 150%;
    font-family: var(--inter-body-medium-14-regular);
    color: var(--color-card-background);
    text-align: left;
  }
  .actionButtons,
  .buttonLabels1 {
    display: flex;
    flex-direction: row;
  }
  .buttonLabels1 {
    cursor: pointer;
    border: 0;
    padding: var(--padding-7xs) var(--padding-5xl);
    background-color: var(--color-cornflowerblue-100);
    border-radius: var(--br-5xs);
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }
  .actionButtons {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-mini);
  }
  /* .detailsWrapper {
    align-self: stretch;
    flex-direction: column;
    gap: var(--gap-xl);
  } */
  /* .detailsWrapper, */
  .patientDetails,
  .profile,
  .profileWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .patientDetails {
    align-self: stretch;
    flex-direction: column;
    gap: var(--gap-xl);
    font-size: var(--font-size-5xl);
    font-family: var(--inter-body-medium-14-regular);
    
  }
  .profile,
  .profileWrapper {
    max-width: 100%;
  }
  .profile {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .profileWrapper {
    width: 342px;
    flex-direction: row;
    padding: 0px 18px;
    box-sizing: border-box;
    font-size: var(--font-size-11xl);
  }
  .mainheading{
    /* width: 342px; */
    flex-direction: row;
    padding: 18px;
    /* box-sizing: border-box; */
    
  }
  .subheading{
    padding: 14px;
  }
  .checkboxheader{
    padding: 14px;
  }
  .buttonheader{
    padding: 14px;
  }
  .echocardiogramExams {
  
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  text-align: left;
 
  }
.subtitle{
    font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
}
.subtitle1{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}
  .detailsWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .exam1 {
    /* text-decoration: none;
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
    color: inherit;
    display: inline-block;
    min-width: 66px; */
    font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  }
  .infoPlaceholderIcon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .examInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-11xs) 0 0;
  }
  .dateValue {
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
    display: inline-block;
    min-width: 8px;
  }
  .examDate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--padding-7xs) 0 0;
  }
  .timePlaceholderIcon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .timeInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-11xs) 0 0;
  }
  .timeValue {
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
    display: inline-block;
    min-width: 8px;
  }
  .examTime {
    flex-direction: row;
    gap: var(--gap-3xs);
  }
  .examDetails,
  .examItem,
  .examTime {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .examDetails {
    flex-direction: row;
    gap: var(--gap-3xs);
    font-size: var(--body1-1-size);
    font-family: var(--body-1);
  }
  .examItem {
    flex-direction: column;
    gap: 15px;
  }
  .timeLabel {
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
    display: inline-block;
    min-width: 107px;
  }
  .timeValues {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 107px;
    max-width: 100%;
  }
  .monthsAgo1 {
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
  }
  .timeValues1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .monthsAgo,
  .timeWrapper {
    justify-content: flex-start;
    max-width: 100%;
  }
  .timeWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--gap-4xs);
  }
  .monthsAgo {
    width: 586px;
    flex-direction: column;
    padding: var(--padding-lg) 0 0;
    box-sizing: border-box;
    margin-left:300px;
    /* background-color: #F9FAFC; */
  }
  .examList,
  .exams,
  .examsContent,
  .monthsAgo {
    display: flex;
    align-items: flex-start;
  }
  .examList {
    /* align-self: stretch; */
    border-radius: 9px;
    background-color:  #F9FAFC;
    flex-direction: row;
    justify-content: space-between;
    /* padding: 0 5px; */
    box-sizing: border-box;
    /* width: 100%; */
    width: 530px;
  height: 220px;
    /* gap: var(--gap-xl);
    font-size: var(--font-size-lg); */
  }
  .exams,
  .examsContent {
    justify-content: flex-start;
  }
  .examsContent {
    flex: 1;
    flex-direction: column;
    /* gap: var(--gap-base); */
    max-width: 100%;
  }
  .exams {
    flex-direction: row;
    padding: 0 var(--padding-12xs);
    box-sizing: border-box;
    font-size: var(--font-size-5xl);
  }
  .exams,
  .frame2731,
  .pageContent {
    align-self: stretch;
    max-width: 100%;
  }
  .frame2731 {
    height: 360.4px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
  }
  .pageContent {
    flex-direction: column;
    gap: 21px;
    text-align: left;
    font-size: var(--m3-label-large-size);
    color: var(--color-black);
    font-family: var(--font-montserrat);
  }
  .content,
  .pageContent,
  .patientDetail {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .content {
    width: 1075px;
    flex-direction: column;
    padding: var(--padding-11xl) 0 0;
    box-sizing: border-box;
    max-width: 100%;
  }
  .patientDetail {
    width: 100%;
    position: relative;
    background-color: var(--color-card-background);
    overflow: hidden;
    flex-direction: row;
    gap: 38px;
    line-height: normal;
    letter-spacing: normal;
    margin-left: 20px;
  }

  .checkbox-container {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust the gap between rows if needed */
  }
  
  .checkbox-row {
    display: flex;
    gap: 20px; /* Adjust the gap between checkboxes if needed */
    padding-right: 20px;
    flex-direction: column;
  }
  .checkboxcolumn{
    flex-direction: column;
    padding: 15px;
  }
  .checkbox-row label {
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 1350px) {
    .patientDetail {
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width: 800px) {
    .patientProfile {
      font-size: var(--font-size-5xl);
      line-height: 36px;
    }
    .examList,
    .timeWrapper {
      flex-wrap: wrap;
    }
    .patientDetail {
      gap: var(--gap-lgi);
    }
  }
  @media screen and (max-width: 450px) {
    .patientProfile {
      font-size: var(--font-size-lg);
      line-height: 27px;
    }
    .echocardiogramExams,
    .eleanoPena {
      font-size: var(--font-size-lgi);
      line-height: 29px;
    }
  }
  /* Container for the file upload component */
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 600px; /* Adjust as needed */
  margin: 20px;
}

/* Style for the file upload button */
.uploadButton {
  display: flex;
  align-items: center;
}

.fileInput {
  display: none; /* Hide the actual file input */
}

.uploadLabel {
  background-color: #007bff; /* Primary button color */
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

/* Style for the file details display */
.fileDetails {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
  background-color: #f8f9fa; /* Light background for file details */
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow */
}

.fileInfo {
  flex-grow: 1;
}

.fileName {
  font-size: 16px;
  font-weight: 500;
}

.fileSize {
  font-size: 14px;
  color: #6c757d; /* Secondary text color */
}

.actions {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.iconWrapper {
  cursor: pointer;
  color: #000; /* Adjust color as needed */
  font-size: 24px; /* Adjust size as needed */
  margin: 0 10px; /* Adjust spacing as needed */
}
