/* Container for the entire page */
.container {
    display: flex;
    height: 100vh; 
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    overflow: hidden;
  }
  
  /* Left side (image section) */
  .imageSection {
    flex: 1; 
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  
  .imageSection img {
    width: 100%; 
    height: auto;
  }

  .backgroundImage {
    width: 100%; 
    height: 100%; 
    display: block; 
  }
  
  /* Right side (form section) */
  .formSection {
  flex: 1;
  /* position: absolute; */
  /* width: 100%;
  height: 100%;
  top: 100px;
  left: 902px; */
  height: 100%;
  max-width: 500px;
  padding: 40px;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); 
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px; 
  border-radius: 8px; 
  }

  .formSection h2 {
    
    width: 500px;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 41px;
    text-align: center;
    color: #FFFFFF;
    background-color: #474D56;
    
  }
  .textstyle{
    font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  text-align: left;
  }
  .textcontrol{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;
    background-color: #474D560F;
  }
  h2 {
    margin-bottom: 20px;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  .registration{
  width: 480px;
  height: 56px;
  position: absolute;
  top: 182px;
  left: 902px;
  gap: 0px;
  opacity: 1; /* Ensure opacity is set to 1 to make the div visible */
  background-color: #fff; /* White background */
  border: 1px solid #ccc; /* Optional border for visibility */
  border-radius: 8px; /* Rounded corners for aesthetics */
  display: flex; /* Flexbox layout */
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
  z-index: 10; /* Ensure the box is above other elements */
  }
  .registration h2 {
    font-family: 'Lato', sans-serif;
    font-size: 18px; /* You can adjust this */
    font-weight: 500;
    line-height: 56px; /* Ensures the text is vertically centered */
    text-align: center;
    margin: 0; /* Removes default margin */
    opacity: 1;
  }
  .formGroup input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .error {
    color: red;
    margin-bottom: 15px;
  }
  .logo {
  position: absolute; /* Overlay the logo on top of the background image */
  top: 250px; /* Adjust positioning as needed */
  left: 50px; /* Adjust positioning as needed */
  width: 50px; /* Set a fixed width */
  height: auto; /* Maintain aspect ratio */
  max-width: 600px; /* Set a maximum width to prevent it from growing too large */
  z-index: 10; /* Ensure the logo is above the background image */
  }
  
  .signupButton {
    padding: 10px;
    width: 100%;
    background-color: #474D56;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
  }
  
  .signupButton:hover {
    background-color: #45a049;
  }
  
  .socialSignin {
    margin-top: 20px;
  }
  
  .facebook {
    width: 144px; /* Width of the button */
    height: 35px; 
    background-size: cover; /* Ensures the image covers the button */
  border: none; /* Remove default border */
  border-radius: 4px;
    cursor: pointer;
    background: url('/images/facebook.png') no-repeat center center;
  }
  .google{
    width: 144px; /* Width of the button */
    height: 35px; 
    background-size: cover; /* Ensures the image covers the button */
  border: none; /* Remove default border */
  border-radius: 4px;
    cursor: pointer;
    background: url('/src/assets/images/google.png') no-repeat center center;
  }
  .git{
    width: 144px; /* Width of the button */
    height: 35px; 
    background-size: cover; /* Ensures the image covers the button */
  border: none; /* Remove default border */
  border-radius: 4px;
    cursor: pointer;
    background: url('/src/assets/images/github.png') no-repeat center center;
  }
  
  .socialButton:hover {
    background-color: #f0f0f0;
  }
  
  .signinLink {
    margin-top: 20px;
  }
  
