:root {
  --padding-4xs: 24px; 
  --font-size-11xl: 32px; 
  --padding-12xs: 18px; 
  --padding-lg: 24px; 
  --font-montserrat: 'Montserrat', sans-serif;
  --gap-xl:5px;
  /* --m3-label-large-size:30px; */
  /* --gap-xl:5px; */
  --gap-4xs:10px;
  --color-card-background:#FFFFFF;
  /* --padding-5xs:5px; */
  --gap-9xs:9px;
}

.breadcrumbItemsCore {
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.text {
  /* position: relative;
  line-height: 150%;
  display: inline-block;
  min-width: 54px; */
  font-family: Mulish, sans-serif;
font-size: 12px;
font-weight: 400;
line-height: 21px;
color: #667085;
}


.breadcrumbItemsCore1,
.breadcrumbItemsCore3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.breadcrumbItemsCore1 {
  gap: var(--gap-5xs);
}


.breadcrumbMain {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: var(--gap-5xs);
}

.patientProfile {
  margin: 0;
  position: relative;
  /* font-size: inherit;
  line-height: 150%;
  font-weight: 600;
  font-family: inherit; */
  font-family: var(--font-montserrat);
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
}

.buttontext {
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 150%;
  font-family: 'Inter', sans-serif;
  color:  #FFFFFF;
  text-align: left;
  display: inline-block;
  min-width: 32px;
}
.buttonLabels {
  cursor: pointer;
  border: 0;
  padding: 7px 14px;
  background-color: #60A5FA;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 120px;
}

.buttonLabels:hover {
  background-color: #6aafe6;
}

.patientDetails
{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.patientDetails {
  align-self: stretch;
  flex-direction: column;
  gap: var(--gap-xl);
  font-size: var(--font-size-5xl);
  font-family: var(--inter-body-medium-14-regular);
  
}

.mainheading{
  /* width: 342px; */
  flex-direction: row;
  padding: 6px;
  /* box-sizing: border-box; */
  
}
.subheading{
  padding: 6px;
}
/* .buttonheader{
  padding: 3px;
} */
.echocardiogramExams {

font-family: 'Montserrat', sans-serif;
font-size: 30px;
font-weight: 600;
line-height: 35px;
text-align: left;

}
.subtitle{
  font-family: 'Montserrat', sans-serif;
font-size: 22px;
font-weight: 500;
line-height: 36px;
text-align: left;
}
.subtitle1{
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding-left: 8px;
}
.detailsWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap-xl);
}
.exam1 {
  /* text-decoration: none;
  position: relative;
  letter-spacing: 0.5px;
  line-height: 24px;
  color: inherit;
  display: inline-block;
  min-width: 66px; */
  font-family: 'Montserrat', sans-serif;
font-size: 18px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.5px;
text-align: left;
}
.infoPlaceholderIcon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.examInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
}

.timePlaceholderIcon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.examDetails {
  flex-direction: row;
  gap: var(--gap-3xs);
  font-size: var(--body1-1-size);
  font-family: var(--body-1);
}
.examItem {
  flex-direction: column;
  gap: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.headerItem{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 15px;
}
.legalInfoItem{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 5px 9px;
}
.examList,
.exams,
.examsContent
 {
  display: flex;
  align-items: flex-start;
}
.examList {
  flex-wrap: wrap;
  border-radius: 9px;
  background-color: #F9FAFC;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  width: 1200px;
  height: 140px;
  margin: 0 auto;
  padding: 0 10px; 
}
.listcontentBox
{
   align-self: stretch;
   border-radius: 9px;
   background-color:  #F9FAFC;
   flex-direction: row;
   justify-content: space-between;
   box-sizing: border-box;
   width: 1200px;
   height: 120px;
   
}
.collapsibleList {
  flex-wrap: wrap;
  border-radius: 9px;
  background-color: #F9FAFC;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  width: 1200px;
  height: 40px;
  margin: 0 auto;
  padding: 0 10px; 
}
.legalInfoList {
  flex-wrap: wrap;
  border-radius: 9px;
  background-color: #F9FAFC;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  width: 1200px;
  height: 40px;
  margin: 0 auto;
  padding: 10px; 

}
.listGap{
  width: 10px; 
  height: 10px; 
}
.releaseText{
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
}
.releaseContentText{
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-align: left;
}
.exams,
.examsContent {
  justify-content: flex-start;
}
.examsContent {
  flex: 1;
  flex-direction: column;
  /* gap: var(--gap-base); */
  max-width: 100%;
}
.exams {
  flex-direction: row;
  padding: 0 var(--padding-12xs);
  box-sizing: border-box;
  font-size: var(--font-size-5xl);
}
.exams,
.pageContent {
  align-self: stretch;
  max-width: 100%;
}
.frameIcon2 {
  height: 20px;
  width: 20px;
  /* position: relative;
  overflow: hidden;
  flex-shrink: 0; */
}
.pageContent {
  flex-direction: column;
  gap: 21px;
  text-align: left;
  font-size: var(--m3-label-large-size);
  color: var(--color-black);
  font-family: var(--font-montserrat);
}
.content,
.pageContent,
.patientDetail {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.content {
 
  flex-direction: column;
  padding: var(--padding-11xl) 0 0;
  box-sizing: border-box;
  max-width: 100%;
}
.patientDetail {
  width: 100%;
  position: relative;
  background-color: var(--color-card-background);
  /* overflow: hidden; */
  flex-direction: row;
  gap: 38px;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 20px;
}

.collapsible {
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.legalContentText{
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #00208F;
}
.collapsibleButton {
  width: 100%;
  font-family: 'Lato', sans-serif;
  background-color: #F9FAFC;
  /* color: #333; */
  padding: 5px;
  text-align: left;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
}

/* .collapsibleButton:hover {
  background-color: #e0e0e0;
} */

.arrow {
  font-size: 20px;
  transition: transform 0.3s;
}

.collapsibleContent {
  padding: 5px;
  display: none;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  background-color: #F9FAFC;
  /* border-top: 1px solid #ccc; */
}

.show {
  display: block;
}

.active .arrow {
  transform: rotate(180deg); /* Rotate the arrow when active */
}


@media screen and (max-width: 1350px) {
  .patientDetail {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 800px) {
  .patientProfile {
    font-size: var(--font-size-5xl);
    line-height: 36px;
  }
  /* .examList,
  .timeWrapper {
    flex-wrap: wrap;
  } */
  .patientDetail {
    gap: var(--gap-lgi);
  }
}
@media (min-width: 1200px) {
  .examList {
   max-width: 1200px; /* Set a max width for larger screens */
  }
}
@media screen and (max-width: 450px) {
  .patientProfile {
    font-size: var(--font-size-lg);
    line-height: 27px;
  }
  .echocardiogramExams,
  .eleanoPena {
    font-size: var(--font-size-lgi);
    line-height: 29px;
  }
}



