:root {
    --padding-4xs: 24px; 
    --font-size-11xl: 32px; 
    --padding-12xs: 16px; 
    --padding-lg: 24px; 
    --font-montserrat: 'Montserrat', sans-serif;
    --gap-xl:5px;
    /* --m3-label-large-size:30px; */
    /* --gap-xl:5px; */
    --gap-4xs:10px;
    --color-card-background:#FFFFFF;
  }
.homeIcon {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .breadcrumbItemsCore {
    display: none;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .chevronRightIcon,
  .homeIcon1 {
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .chevronRightIcon {
    height: 16px;
    width: 16px;
    display: none;
  }
  .homeIcon1 {
    height: 24px;
    width: 24px;
  }
  .breadcrumbItemsCore2 {
    display: none;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .text {
    /* position: relative;
    line-height: 150%;
    display: inline-block;
    min-width: 54px; */
    font-family: Mulish, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: #667085;
  }
  .textpatient {
    /* position: relative;
    line-height: 150%;
    display: inline-block;
    min-width: 54px; */
    font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #06080b;
  }
  .breadcrumbItemsCore1,
  .breadcrumbItemsCore3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .breadcrumbItemsCore1 {
    gap: var(--gap-5xs);
  }
  .chevronRightIcon1 {
    height: 16px;
    width: 16px;
    position: relative;
    /* overflow: hidden;
    flex-shrink: 0; */
  }
  .dotIcon
  {
    position: relative;
  }
  .dotIcon {
    height: 8px;
    width: 8px;
    display: none;
  }
  .icon {
    height: 12px;
    width: 12px;
    position: relative;
  }
  .patientname {
    position: relative;
    line-height: 150%;
    display: inline-block;
    min-width: 99px;
  }
  .iconLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .leftContent {
    flex-direction: row;
    gap: var(--gap-9xs);
    color: var(--tailwind-gray900);
    font-family: var(--inter-body-medium-14-regular);
  }
  .breadcrumbMain,
  .breadcrumbWrapper,
  .leftContent,
  .mainContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .breadcrumbMain {
    flex-direction: row;
    gap: var(--gap-5xs);
  }
  .breadcrumbWrapper
   {
    flex-direction: column;
    padding: 0 var(--padding-5xs);
  }
  .mainContent {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 var(--padding-12xs) var(--padding-lg);
    
  }
  .patientProfile {
    margin: 0;
    position: relative;
    /* font-size: inherit;
    line-height: 150%;
    font-weight: 600;
    font-family: inherit; */
    font-family: var(--font-montserrat);
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
  }
  .image5Icon {
    width: 90px;
    height: 90px;
    position: relative;
    border-radius: var(--br-81xl);
    object-fit: contain;
  }
  .imageIcon {
    width: 30px;
    height: 30px;
   
    object-fit: contain; 
    
  }
  .imagepdfIcon {
    width: 30px;
    height: 30px;
   padding: 8px;
    object-fit: contain; 
    
  }
  .iconContainer {
    display: flex;
    justify-content: center; 
    align-items: center; 
    padding-bottom: 10px;   
    /* width: 100%;            
    height: 100%;            */
  }
  .eleanoPena
   {
    position: relative;
    /* line-height: 150%; */
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

  }
  .genderMaleM {
   font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  }
  .nameDob {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3px;
  }
  .buttontext {
    position: relative;
    font-size: var(--font-size-lg);
    line-height: 150%;
    font-family: 'Inter', sans-serif;
    color:  #FFFFFF;
    text-align: left;
    display: inline-block;
    min-width: 32px;
  }
  .buttonLabels {
    cursor: pointer;
    border: 0;
    padding: 7px 14px;
    background-color: #60A5FA;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
  }
  
  .buttonLabels:hover {
    background-color: #6aafe6;
  }
  .bookAppointment {
    position: relative;
    font-size: var(--font-size-lg);
    line-height: 150%;
    font-family: var(--inter-body-medium-14-regular);
    color: var(--color-card-background);
    text-align: left;
  }
  .actionButtons,
  .buttonLabels1 {
    display: flex;
    flex-direction: row;
  }
  .buttonLabels1 {
    cursor: pointer;
    border: 0;
    padding: var(--padding-7xs) var(--padding-5xl);
    background-color: var(--color-cornflowerblue-100);
    border-radius: var(--br-5xs);
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }
  .actionButtons {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-mini);
  }
  /* .detailsWrapper {
    align-self: stretch;
    flex-direction: column;
    gap: var(--gap-xl);
  } */
  /* .detailsWrapper, */
  .patientDetails,
  .profile,
  .profileWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .patientDetails {
    align-self: stretch;
    flex-direction: column;
    gap: var(--gap-xl);
    font-size: var(--font-size-5xl);
    font-family: var(--inter-body-medium-14-regular);
    
  }
  .profile,
  .profileWrapper {
    max-width: 100%;
  }
  .profile {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .profileWrapper {
    width: 342px;
    flex-direction: row;
    padding: 0px 18px;
    box-sizing: border-box;
    font-size: var(--font-size-11xl);
  }
  .echocardiogramExams {
    /* 
    line-height: 150%;
    font-weight: 500; */
    /* position: relative; */
    font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
 
  }

  .detailsWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap-xl);
  }
  .exam1 {
    /* text-decoration: none;
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
    color: inherit;
    display: inline-block;
    min-width: 66px; */
    font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  }
  .infoPlaceholderIcon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .examInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-11xs) 0 0;
  }
  .dateValue {
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
    display: inline-block;
    min-width: 8px;
  }
  .examDate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 var(--padding-7xs) 0 0;
  }
  .timePlaceholderIcon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .timeInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-11xs) 0 0;
  }
  .timeValue {
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
    display: inline-block;
    min-width: 8px;
  }
  .examTime {
    flex-direction: row;
    gap: var(--gap-3xs);
  }
  .examDetails,
  .examItem,
  .examTime {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .examDetails {
    flex-direction: row;
    gap: var(--gap-3xs);
    font-size: var(--body1-1-size);
    font-family: var(--body-1);
  }
  .examItem {
    flex-direction: column;
    gap: 15px;
  }
  .timeLabel {
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
    display: inline-block;
    min-width: 107px;
  }
  .timeValues {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 107px;
    max-width: 100%;
  }
  .monthsAgo1 {
    position: relative;
    letter-spacing: 0.5px;
    line-height: 24px;
  }
  .timeValues1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .monthsAgo,
  .timeWrapper {
    justify-content: flex-start;
    max-width: 100%;
  }
  .timeWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--gap-4xs);
  }
  .monthsAgo {
    width: 586px;
    flex-direction: column;
    padding: var(--padding-lg) 0 0;
    box-sizing: border-box;
    margin-left:300px;
    /* background-color: #F9FAFC; */
  }
  .examList,
  .exams,
  .examsContent,
  .monthsAgo {
    display: flex;
    align-items: flex-start;
  }
  .examList {
    /* align-self: stretch; */
    border-radius: 9px;
    background-color:  #F9FAFC;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5px; 
    box-sizing: border-box;
    width: 100%;
    /* gap: var(--gap-xl);
    font-size: var(--font-size-lg); */
  }
  .exams,
  .examsContent {
    justify-content: flex-start;
  }
  .examsContent {
    flex: 1;
    flex-direction: column;
    gap: 8px;
    max-width: 100%;
  }
  .exams {
    flex-direction: row;
    padding: 0 var(--padding-12xs);
    box-sizing: border-box;
    font-size: var(--font-size-5xl);
  }
  .exams,
  .frame2731,
  .pageContent {
    align-self: stretch;
    max-width: 100%;
  }
  .frame2731 {
    height: 360.4px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
  }
  .pageContent {
    flex-direction: column;
    gap: 21px;
    text-align: left;
    font-size: var(--m3-label-large-size);
    color: var(--color-black);
    font-family: var(--font-montserrat);
  }
  .content,
  .pageContent,
  .patientDetail {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .content {
    width: 1075px;
    flex-direction: column;
    padding: var(--padding-11xl) 0 0;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .patientDetail {
    max-height: 100vh;
    /* position: relative; */
    background-color: var(--color-card-background);
    overflow: auto;
    flex-direction: row;
    gap: 38px;
    line-height: normal;
    letter-spacing: normal;
    margin-left: 20px;
  }

  .patientDetail::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge, and Firefox */
  .patientDetail {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  @media screen and (max-width: 1350px) {
    .patientDetail {
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width: 800px) {
    .patientProfile {
      font-size: var(--font-size-5xl);
      line-height: 36px;
    }
    .examList,
    .timeWrapper {
      flex-wrap: wrap;
    }
    .patientDetail {
      gap: var(--gap-lgi);
    }
  }
  @media screen and (max-width: 450px) {
    .patientProfile {
      font-size: var(--font-size-lg);
      line-height: 27px;
    }
    .echocardiogramExams,
    .eleanoPena {
      font-size: var(--font-size-lgi);
      line-height: 20px;
    }
  }

  /* .container {
    display: flex;
    width: 100%;
  }
   */
  
  /* Container for the file upload component */
.container {
  display: flex;
  
  align-items: flex-start;
 
  margin: 20px;
  width: 394px; 
  height: 210px; 
  /* position: absolute;  */
  /* top: 652px;  */
  /* left: 383px;  */
  /* padding: 0 0 0 0;  */

  border: 2px dashed #CBD0DC;

}
.fileuploadcontainer
{
  flex: 1; 
  padding: 10px 50px;
  box-sizing: border-box; 

}
.uploadbarcontainer{
  flex: 1; 
  padding: 20px 60px; 
  box-sizing: border-box; 
  margin: 0;
  /* border: 1px solid #ddd; */
}
.scantext {
  font-family: Inter, sans-serif; 
  font-size: 18px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: center;
  color: #292D32;
  padding-bottom: 10px;
}
.scantext1{
  font-family: Inter, sans-serif; 
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #A9ACB4;
  white-space: nowrap;
  padding-bottom: 10px;
}
.scantext2{
  font-family: Inter, sans-serif; 
  font-size: 18px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  color: #A9ACB4;
  white-space: nowrap;
}
.solidlineleft{
  flex: 1;
  border-top: 1px solid #B2B2B2; 
  margin: 0 10px; 
  width: 50%;
}
.solidlineright{
  flex: 1;
  border-top: 1px solid #B2B2B2; 
  margin: 0 10px; 
  width: 50%;
}
.linecontainer{
  display: flex;
  align-items: center;
  width: 100%; 
  padding-bottom: 10px;
}
/* Style for the file upload button */
.uploadButton {
  display: flex;
  align-items: center;
}

.fileInput {
  display: none; 
}

.uploadLabel {
  font-family: Inter, sans-serif;
  background-color:  #60A5FA ;
  color: white;
  /* padding: 10px; */
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
   text-align: center;
  width: 120px; 
  height: 20px; 
  padding: 10px 80px; 
  /* gap: 10px;  */
 
}

.fixedbox {
  width: 394px; 
  height: 230px; 
  position: absolute; 
  top: 652px; 
  left: 383px; 
  padding: 20px 0 0 0; 
  gap: 20px; 
  border-radius: 12px 0 0 0; 
  border: 1px solid #000; 
  
}

/* Style for the file details display */
/* .fileDetails {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
  background-color: #23476b; 
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
} */

.fileInfo {
  /* flex-grow: 1; */
  display: flex;
  /* align-items: center; */
  /* margin-top: 10px; */
  margin: 5px;
}

.fileName {
  font-size: 16px;
  font-weight: 500;
}

.fileSize {
  font-size: 14px;
  color: #6c757d; 
}

.actions {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.iconWrapper {
  cursor: pointer;
  color: #000; 
  font-size: 24px; 
  margin: 0 10px; 
}
.uploadButton {
  margin-top: 10px;
}

.fileInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
}
.filenamedisplay{
  display: flex;
  width: 100%;
  height: 40px;
  width: 480px;
  border-radius: 9px;
background-color:  #F9FAFC;
box-sizing: border-box;

}
.fileName {
  font-size: 16px;
  margin: 10px;
  padding-left: 10px;
}

.uploadProgress {
  width: 100%;
  background-color: #5e51ec;
  border-radius: 4px;
  position: relative;
  height: 20px;
  margin-bottom: 10px;
}

.progressBar {
  background-color: #2a0c56;
  height: 100%;
  border-radius: 4px;
  transition: width 0.5s ease;
}

.progressText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  /* color: #fff; */
}

.actions {
  display: flex;
  gap: 10px;
}

.cancelButton,
.deleteButton {
  /* background: none; */
  border: none;
  /* color: #333; */
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.cancelButton:hover,
.deleteButton:hover {
  color: #ff6b6b;
}