:root {
  --gap-5xs: 8px; 
  --padding-114xl: 10px; 
  --color-snow:  ##FFFFFF;
  --color-gainsboro-200:#E3E3E3;
  --gap-9xs: 4px;
  --capital-gray-geist:'Helvetica, Arial, sans-serif';
  --body-1-size: 16px;
  --tailwind-gray700: #374151;
  --gap-xs: 12px;
  --gap-medium: 12px;
  /* --gap-base: 16px; */
  --width-fill: 280px; 
  --height-fill: 300px; 
  --gap-15xs: 15px;
  --background-color: #ffffff; /* Default background color */
  --hover-background-color: #f0f0f0; /* Hover background color */
}

.menuButton {
 
  align-items: center; 
  width: 240px; 
    height: auto; 
    padding: 8px 12px 8px 12px;
    display: flex;
    gap: var(--gap-medium); 
    border-radius: 4px 0px 0px 0px; 
  background-color: var(--background-color); /* Background color */
  border: none; /* Remove default button border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition for background color */
}

.menuButton:hover {
  background-color: var(--hover-background-color); /* Background color on hover */
}

.sidebar {
    width: 250px;
    height: 100vh;
    background-color: #333;
    color: white;
    padding: 20px;
    position: fixed;
  }
  
  .sidebar nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  .sidebar nav ul li {
    margin: 10px 0;
  }
  
  .sidebar nav ul li a {
    color: white;
    text-decoration: none;
  }
  
  .sidebar nav ul li a.active-link {
    font-weight: bold;
  }


  .image3Icon {
    height: 27.7px;
    width: 32px;
    position: relative;
    object-fit: contain;
  }
   .myocardi {
    
    font-family: 'Poppins';
    font-weight: 500; /* Weight 500 */
    font-size: 22px; /* Font size 22px */
    line-height: 33px; /* Line height 33px */
    color:#950000;

  }
  .logoBlock {
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-114xl);
    gap: var(--gap-5xs); */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 var(--padding-114xl) 0 0;
    gap: 20px;
  }
  .someOtherClassName {
    margin-bottom: 2000px; /* Adjust the value as needed for the desired space */
  }
  .icon {
    height: 20px;
    width: 20px;
    position: relative;
  }
  .buttonIcon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-7xs);
  }
  .icon1 {
    height: 20px;
    width: 20px;
    position: relative;
  }
  .buttonIcon1,
  .icons {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .buttonIcon1 {
    display: flex;
    padding: var(--padding-7xs);
  }
  .icons {
    display: none;
    z-index: 2;
  }
  .navigationHeader {
    width: 100%;
    max-width: 280px;
    height: 33px; 
    justify-content: space-between;
    
  }
  .typography {
    font-family: 'Poppins', sans-serif; /* Set the font family to Poppins */
    
 }
  .workspaces {
    width: 226px;
    /* position: relative; */
    line-height: 150%;
    text-transform: uppercase;
    display: inline-block;
    font-family: Geist;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color:#6B7280;
    padding: 30px 10px 10px 5px;
  }
  .dotIcon {
    height: 8px;
    width: 8px;
    position: relative;
    display: none;
  }
  .frameIcon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .myPatients {
    width: calc(100% - 20px);
    border: 0;
    outline: 0;
    height: 24px;
    flex: 1;
    position: relative;
    line-height: 150%;
    text-align: left;
    display: inline-block;
    min-width: 134px;
    padding: 0;
  }
  .iconLabel,
  .leftContent {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xs);
  }
  .leftContent {
    gap: var(--gap-9xs);
  }
  .badge,
  .text {
    align-self: stretch;
  }
  .text {
    position: relative;
    line-height: 150%;
    font-weight: 500;
  }
  .badge {
    border-radius: var(--br-base);
    background-color: var(--neutral-20);
    border: 1px solid var(--neutral-30);
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-11xs) var(--padding-5xs);
  }
  .icon2 {
    height: 20px;
    width: 20px;
    position: relative;
  }
  .menuItem,
  .rightContent {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .rightContent {
    height: 22px;
    display: none;
    padding: 0 var(--padding-19xl) 0 0;
    box-sizing: border-box;
    gap: var(--gap-5xs);
  }
  .menuItem {
    /* align-self: stretch;
    border-radius: var(--br-9xs);
    background-color: var(--color-gainsboro-400);
    overflow: hidden;
    display: flex;
    padding: var(--padding-5xs) var(--padding-xs);
    gap: var(--gap-xs);
    text-align: center;
    color: var(--neutral-100);
    font-family: var(--inter-body-medium-14-regular); */
    width: 280px; 
    height: auto; 
    padding: 8px 12px 8px 12px;
    display: flex;
    gap: var(--gap-medium); 
    border-radius: 4px 0px 0px 0px; 
    
  }
  .dotIcon1 {
    height: 8px;
    width: 8px;
    position: relative;
    display: none;
  }
  .frameIcon1 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .frameIcon2 {
    height: 10px;
    width: 10px;
    /* position: relative;
    overflow: hidden;
    flex-shrink: 0; */
  }
  .scanningActivity {
    /* flex: 1;
    position: relative;
    line-height: 150%; */
    width: calc(100% - 20px);
    border: 0;
    outline: 0;
    height: 24px;
    flex: 1;
    position: relative;
    line-height: 150%;
    text-align: left;
    display: inline-block;
    min-width: 134px;
    padding: 0;
  }
  .iconLabel1,
  .leftContent1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .iconLabel1 {
    flex: 1;
    gap: var(--gap-5xs);
  }
  .leftContent1 {
    width: 220px;
    gap: var(--gap-9xs);
  }
  .badge1,
  .text1 {
    align-self: stretch;
  }
  .text1 {
    position: relative;
    line-height: 150%;
    font-weight: 500;
  }
  .badge1 {
    border-radius: var(--br-base);
    background-color: var(--neutral-20);
    border: 1px solid var(--neutral-30);
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-11xs) var(--padding-5xs);
  }
  .icon3 {
    height: 20px;
    width: 20px;
    position: relative;
  }
  .rightContent1 {
    height: 22px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 var(--padding-19xl) 0 0;
    box-sizing: border-box;
    gap: var(--gap-5xs);
    text-align: center;
    font-size: var(--capital-gray-geist-size);
    color: var(--neutral-100);
    font-family: var(--inter-body-medium-14-regular);
  }
  
  .navigation
   {
    /* align-self: stretch; */
    /* display: flex;
    justify-content: flex-start; */
    /* flex-direction: column;
    align-items: flex-start; */
    width: var(--width-fill); 
    height: var(--height-fill);
  }
  
  .informationCenter {
    flex: 1;
    line-height: 150%;
    color: #6B7280;
    width: 226px;
    position: relative;
    text-transform: uppercase;
    display: inline-block;
    font-family: Geist;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    padding: 10px;
  }
  .title {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-5xs);
    font-size: var(--capital-gray-geist-size);
    color: var(--tailwind-gray500);
  }
  .dotIcon2 {
    height: 8px;
    width: 8px;
    position: relative;
    display: none;
  }
  .frameIcon2 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .about {
    /* flex: 1;
    position: relative;
    line-height: 150%;
    white-space: nowrap; */
    width: calc(100% - 20px);
    border: 0;
    outline: 0;
    height: 24px;
    flex: 1;
    position: relative;
    line-height: 150%;
    text-align: left;
    display: inline-block;
    min-width: 134px;
    padding: 0;

  }
  .iconLabel2,
  .leftContent2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .iconLabel2 {
    flex: 1;
    gap: var(--gap-5xs);
  }
  .leftContent2 {
    width: 220px;
    gap: var(--gap-9xs);
  }
  .badge2,
  .text2 {
    align-self: stretch;
  }
  .text2 {
    position: relative;
    line-height: 150%;
    font-weight: 500;
  }
  .badge2 {
    border-radius: var(--br-base);
    background-color: var(--neutral-20);
    border: 1px solid var(--neutral-30);
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-11xs) var(--padding-5xs);
  }
  .icon4 {
    height: 20px;
    width: 20px;
    position: relative;
  }
  .menuItem2,
  .rightContent2 {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .rightContent2 {
    height: 22px;
    display: none;
    padding: 0 var(--padding-19xl) 0 0;
    box-sizing: border-box;
    gap: var(--gap-5xs);
    text-align: center;
    font-size: var(--capital-gray-geist-size);
    color: var(--neutral-100);
    font-family: var(--inter-body-medium-14-regular);
  }
  .menuItem2 {
    align-self: stretch;
    border-radius: var(--br-9xs);
    overflow: hidden;
    display: flex;
    padding: var(--padding-5xs) var(--padding-xs);
    gap: var(--gap-xs);
  }
  .dotIcon3 {
    height: 8px;
    width: 8px;
    position: relative;
    display: none;
  }
  .frameIcon3 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .support {
    /* flex: 1;
    position: relative;
    line-height: 150%; */
    width: calc(100% - 20px);
    border: 0;
    outline: 0;
    height: 24px;
    flex: 1;
    position: relative;
    line-height: 150%;
    text-align: left;
    display: inline-block;
    min-width: 134px;
    padding: 0;
  }
  
  .iconLabel3,
  .leftContent3,
  .supportWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .iconLabel3 {
    flex: 1;
    gap: var(--gap-5xs);
  }
  .leftContent3 {
    width: 220px;
    gap: var(--gap-9xs);
  }
  .badge3,
  .text3 {
    align-self: stretch;
  }
  .text3 {
    position: relative;
    line-height: 150%;
    font-weight: 500;
  }
  .badge3 {
    border-radius: var(--br-base);
    background-color: var(--neutral-20);
    border: 1px solid var(--neutral-30);
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-11xs) var(--padding-5xs);
  }
  .icon5 {
    height: 20px;
    width: 20px;
    position: relative;
  }
  .menuItem3,
  .rightContent3 {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .rightContent3 {
    height: 22px;
    display: none;
    padding: 0 var(--padding-19xl) 0 0;
    box-sizing: border-box;
    gap: var(--gap-5xs);
    text-align: center;
    font-size: var(--capital-gray-geist-size);
    color: var(--neutral-100);
    font-family: var(--inter-body-medium-14-regular);
  }
  .menuItem3 {
    align-self: stretch;
    border-radius: var(--br-9xs);
    overflow: hidden;
    display: flex;
    padding: var(--padding-5xs) var(--padding-xs);
    gap: var(--gap-xs);
  }
  .image4Icon {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: var(--br-81xl);
    object-fit: contain;
  }
  .administrationAccount,
  .hellomyocardiio {
    position: relative;
    line-height: 150%;
  }
  .hellomyocardiio {
    color: var(--tailwind-gray500);
    display: inline-block;
    min-width: 121px;
    white-space: nowrap;
  }
  .avatarLabel,
  .nameEmail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .avatarLabel {
    flex-direction: row;
    gap: 20px;
  }
  .icon6 {
    margin: 0;
    height: 20px;
    width: 20px;
    position: relative;
  }
  .account {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: var(--padding-5xs) var(--padding-xs);
   
    font-size: var(--m3-label-large-size);
    color: var(--tailwind-gray900);
    font-family: var(--inter-body-medium-14-regular);
  }
  .navigation2,
  /* .sidebarNavigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

  } */
  .navigation2 {
    align-self: stretch;
    gap: var(--gap-xs);
    font-size: var(--body-1-size);
    color: var(--tailwind-gray700);
  }
  .sidebarNavigation {
    min-height: 100vh;
    width: 270px;
    background-color: var(--color-snow);
    border-right: 1px solid var(--color-gainsboro-200);;
    box-sizing: border-box;
    padding: 8px;
    text-align: left;

    
  }
  @media screen and (max-width: 925px) {
    .sidebarNavigation {
      display: none;
      padding-top: var(--padding-xl);
      padding-bottom: var(--padding-xl);
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 450px) {
    .myocardi {
      font-size: var(--font-size-lg);
    }
    
  }
  
  