:root {
    --width: 98px;
    --height: 32px;
    --padding-top: 6px;
    --padding-right: 8px;
    --padding-bottom: 6px;
    --padding-left: 8px;
    --gap: 8px;
    --font-family: 'Geist', sans-serif;
    --font-size: 14px;
    --font-weight: 400;
    --line-height: 18px;
    --text-align: left;
    --br-5xs: 4px; 
    --tailwind-gray500: #6B7280; 
    --padding-2xs: 8px; 
    --br-xl: 5px;
    --gap-5xs: 5px;
    --padding-7xs: 10px; 
    --padding-5xs: 8px; 
    --padding-xs: 5px; 
    --br-11xl: 20px;
    --padding-mid: 12px;
    --br-22xl: 16px; 
    --color-whitesmoke-100: #f5f5f5; 
    --padding-7xs: 8px; 
    --padding-base: 12px; 
}

.filterBy {
    position: absolute;
    /* margin: 0 !important; */
    /* padding: 10px; */
    top: 31px;
    left: 16px;
    
    line-height: 150%;
    display: inline-block;
    min-width: 60px;
    font-family: var(--font-family);
    font-size: var(--font-size);
    font-weight: var(--font-weight);
    line-height: var(--line-height);
    text-align: var(--text-align);
    color: #6B7280;
    text-transform: uppercase;
    margin-right: 20px;
  }
  .frameIcon {
     /* height: 20px;
    width: 20px; */
    height: 15px;
    width: 15px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    
  }
  .frameIconcategory {
    /* height: 20px;
   width: 20px; */
   height: 15px;
   width: 15px;
   position: relative;
   overflow: hidden;
   flex-shrink: 0;
   padding-right: 8px;
 }
  .dropdownLabels {
    position: relative;
    /* line-height: 150%;
    font-weight: 500; */
    display: inline-block;
    min-width: 30px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #6B7280;
  }
  .dropdownLabels1 {
    position: relative;
    /* line-height: 150%;
    font-weight: 500; */
    display: inline-block;
    min-width: 30px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #6B7280;
    padding-left: 10px;
  }
  .frameIcon1 {
    height: 12px;
    width: 12px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .stateLayer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding: var(--padding-7xs-5) var(--padding-5xs);
    gap: var(--gap-5xs); */
    width: var(--width);
    height: var(--height);
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
    display: flex;
    align-items: center;
    gap: var(--gap);
    box-sizing: border-box;
  }
  .stateLayercategory {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding: var(--padding-7xs-5) var(--padding-5xs);
    gap: var(--gap-5xs); */
    width: 120px;
    height: var(--height);
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
    /* padding: var(--padding-top) 8px var(--padding-bottom) 2px; */
    /* --padding-top: 6px;
    --padding-right: 8px;
    --padding-bottom: 6px;
    --padding-left: 8px; */
   
    
    gap: var(--gap);
    box-sizing: border-box;
  }
  
  .component156 {
    border-radius: var(--br-5xs);
    background-color: var(--color-lavenderblush);
    border: 1px solid var(--tailwind-gray500);
    overflow: hidden;
    padding: 0 var(--padding-2xs);
    
  }
  .componentcategory {
    border-radius: var(--br-5xs);
    background-color: var(--color-lavenderblush);
    border: 1px solid var(--tailwind-gray500);
    overflow: hidden;
    padding: 0 var(--padding-2xs);
    cursor: pointer;
  }
  .categoryList {
    /* Styles for the category list */
    padding: 8px;
    border-top: 1px solid #ccc;
  }
  .frameIcon2 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .labelText {
    flex: 1;
    position: relative;
    line-height: 150%;
    font-weight: 500;
  }
  .frameIcon3 {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .stateLayer1 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: var(--width);
    height: var(--height);
    padding: var(--padding-top) var(--padding-right) var(--padding-bottom) var(--padding-left);
    display: flex;
    align-items: center;
    gap: var(--gap);
    box-sizing: border-box;
  }
  .horizontalline{
    width: 1000px;
    border-bottom: 1px solid ;
    margin-left: 10px; 
    margin-right: 20px; 
    background-color:#6B7280 ;
  }
  
  .frameIcon4 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .labelText1 {
    flex: 1;
    position: relative;
    line-height: 150%;
    font-weight: 500;
    display: inline-block;
    min-width: 49px;
  }
  .frameIcon5 {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .component167,
  .stateLayer2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .stateLayer2 {
    flex: 1;
    padding: var(--padding-7xs-5) var(--padding-5xs);
    gap: var(--gap-5xs);
  }
  .component167 {
    width: 141px;
    border-radius: var(--br-5xs);
    border: 1px solid var(--tailwind-gray500);
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    padding: 0 var(--padding-2xs);
  }
  
  /* .filterDropdown {
    position: absolute;
    margin: 0 !important;
  } */
  .filterDropdown {
    position: absolute;
    width: 452px;
    top: 24px;
    left: 84px;
    padding-left:10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    max-width: 100%;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    color: var(--tailwind-gray700);
    font-family: var(--capital-gray-geist);
     
  }
  .filterChild {
    height: 1px;
    width: 1145px;
    top: 72px;
    left: 8px;
    border-top: 1px solid var(--color-disabled);
    box-sizing: border-box;
  }
  .chipLabel {
    flex: 1;
    position: relative;
    line-height: 150%;
    font-weight: 500;
  }
  .trailingIcon {
    height: 18px;
    width: 18px;
    position: relative;
  }
  .stateLayer4 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .stateLayer3 {
    align-items: center;
    justify-content: center;
    padding: var(--padding-7xs) var(--padding-5xs) var(--padding-7xs)
      var(--padding-xs);
  }
 
  .inputChipDark {
    flex: 1;
    display: flex;
    flex-direction: row;
    height: 32px;
    border-radius: 5px;
    border: 1px solid var(--tailwind-gray500);
    box-sizing: border-box;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: var(--padding-7xs) var(--padding-5xs) var(--padding-7xs)
    var(--padding-xs);
  }
  .component157 {
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 151px;
  }
  .labelText2 {
    text-decoration: none;
    position: relative;
    letter-spacing: 0.1px;
    line-height: 20px;
    font-weight: 500;
    color: inherit;
    display: inline-block;
    min-width: 30px;
  }
  .trailingIcon1 {
    height: 18px;
    width: 18px;
    position: relative;
  }
  .stateLayer6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .stateLayer5 {
    align-items: center;
    justify-content: center;
    padding: var(--padding-7xs) var(--padding-5xs) var(--padding-7xs)
      var(--padding-xs);
  }
  .component163,
  .inputChipDark1,
  .stateLayer5 {
    display: flex;
    flex-direction: row;
  }
  .inputChipDark1 {
    border-radius: var(--br-xl);
    border: 1px solid var(--tailwind-gray500);
    overflow: hidden;
    align-items: center;
    justify-content: center;
    /* gap: var(--gap-5xs); */
    height: 32px;
    box-sizing: border-box;
    padding: var(--padding-7xs) var(--padding-5xs) var(--padding-7xs)
    var(--padding-xs);

  }
  .component163 {
    align-items: flex-start;
    justify-content: flex-start;
    font-family: var(--body-1);
  }
  .labelText3 {
    text-decoration: none;
    position: relative;
    font-size: var(--m3-label-large-size);
    letter-spacing: 0.1px;
    line-height: 20px;
    font-weight: 500;
    font-family: var(--body-1);
    color: var(--color-black);
    text-align: center;
    display: inline-block;
    min-width: 54px;
  }
  .filterChipDark,
  .stateLayer7 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .stateLayer7 {
    border-radius: var(--br-22xl);
    background-color: var(--color-whitesmoke-100);
    padding: var(--padding-7xs) var(--padding-base);
    white-space: nowrap;
  }
  .filterChipDark {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    border-radius: var(--br-11xl);
    background: linear-gradient(
        rgba(255, 255, 255, 0.05),
        rgba(255, 255, 255, 0.05)
      ),
      rgba(255, 255, 255, 0.2);
    overflow: hidden;
  }
  .component164,
  .filter,
  .filterChip {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .component164 {
    align-items: flex-start;
    padding: 0 var(--padding-mid) 0 0;
  }
  .filter,
  .filterChip {
    align-items: center;
    max-width: 100%;
  }
  .filterChip {
    width: 435.7px;
    
    position: absolute;
    top: 88px;
    left: 18px;
    gap: 20px;
    text-align: center;
    font-size: var(--m3-label-large-size);
    color: var(--tailwind-gray700);
  }
  .filter {
    align-self: stretch;
    height: 144px;
    flex-wrap: wrap;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    gap: 16px 19px;
    z-index: 1;
    text-align: left;
    font-size: var(--capital-gray-geist-size);
    color: var(--tailwind-gray500);
    font-family: var(--capital-gray-geist);
  }
  @media screen and (max-width: 450px) {
    .filterChip,
    .filterDropdown {
      flex-wrap: wrap;
    }
    .filter {
      height: auto;
      min-height: 144;
    }
  }
  